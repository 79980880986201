<template>
  <div class="home">
    <Header/>
    <!-- Swiper-->

    <!-- A Few Words About Our Bank-->
    <section class="section section-lg bg-gray-100 bg-light-figure" style="padding-top:200px">
      <div class="container">
        <div class="row row-20 justify-content-center justify-content-lg-between">
          <div class="col-md-10 col-lg-6 wow fadeIn"><img class="out-of-container" src="@/assets/images/index-1-691x452.png" alt="" width="691" height="452"/>
          </div>
          <div class="col-md-10 col-lg-6">
            <div class="block-lg text-left">
              <h2>智云视图资质荣誉</h2>
              <!-- <p class="sup pr-xl-5">We're a world-class web hosting company who offers a range of hosting services, including web hosting, reseller hosting,</p> -->
            </div>
            <div class="text-block-2" style="margin-top: 50px">
              <p>以人为本，客户至上，坚持以客户中心的服务方向。追求发展、不断创新，追求卓越、永续发展。</p>
              <div class="progress-linear-wrap">
                
              </div><a class="button button-lg button-secondary" href="/#about">查看更多</a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section section-sm">
      <div class="container">
        <div class="row row-20 justify-content-center">
          <div class="col-md-12 col-lg-4">
            <img style="padding:0 30px" src="@/assets/images/home/award_1.jpg"/>
            <p>高新技术企业</p>
          </div>
          <div class="col-md-12 col-lg-4">
            <img style="padding:0 30px" src="@/assets/images/home/award_2.jpg"/>
            <p>高新技术企业</p>
          </div>
          <div class="col-md-12 col-lg-4">
            <img style="padding:0 30px" src="@/assets/images/home/award_3.jpg"/>
            <p>高新技术企业</p>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="row row-20 justify-content-center">
          <div class="col-md-12 col-lg-4">
            <img style="padding:0 30px" src="@/assets/images/home/zzq_1.jpg"/>
            <p>高新技术企业</p>
          </div>
          <div class="col-md-12 col-lg-4">
            <img style="padding:0 30px" src="@/assets/images/home/zzq_2.jpg"/>
            <p>高新技术企业</p>
          </div>
          <div class="col-md-12 col-lg-4">
            <img style="padding:0 30px" src="@/assets/images/home/zzq_3.jpg"/>
            <p>高新技术企业</p>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="row row-20 justify-content-center">
          <div class="col-md-12 col-lg-4">
            <img style="padding:0 30px" src="@/assets/images/home/zzq_4.jpg"/>
            <p>高新技术企业</p>
          </div>
          <div class="col-md-12 col-lg-4">
            <img style="padding:0 30px" src="@/assets/images/home/zzq_5.jpg"/>
            <p>高新技术企业</p>
          </div>
          <div class="col-md-12 col-lg-4">
            <img style="padding:0 30px" src="@/assets/images/home/zzq_6.jpg"/>
            <p>高新技术企业</p>
          </div>
        </div>
      </div>
    </section>
    <Bottom/>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Bottom from "@/components/Bottom";
import initFunc from '@/assets/js/script'

export default {
  name: 'About',
  mounted() {
    initFunc()
  },
  components: {
    Bottom, Header
  }
}
</script>

<style scoped>
    a {
        text-decoration: none;
    }
</style>
